import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Container from "../components/container";
import { Link } from "gatsby";
import { FaCommentDollar, FaCloud, FaFile } from "react-icons/fa";
import * as styles from "./index.module.css";

const Metodyfinansowania = ({}) => {
  return (
    <FunctionPageLayout
      title="Metody finansowania"
      headline="projektów wdrożeniowych"
    >
      <SEO
        title="Metody finansowania oprogramowania IT dla biznesu"
        description="Metody finansowania ✅ Zakup systemu • Wynajem systemu • Leasing systemu • Metody finansowania dla projektów IT"
        keywords={["metody finansowania"]}
      />
      <p>
        <strong>
          Zastanawiasz się nad tym, jak możesz sfinansować wdrożenie
          oprogramowania IT?
        </strong>
      </p>
      <p>
        Umożliwiamy finansowanie projektów wdrożeniowych na różne sposoby, a
        każdy z nich ma swoje indywidualne zalety. Warto zapoznać się z plusami
        każdego rozwiązania, aby wybrać to, które najlepiej odpowiada potrzebom
        Twojej organizacji.
      </p>
      <br />
      <h3>Dostępne modele finansowania:</h3>
      <BenefitBlockWrapper>
        <BenefitBlock icon={<FaCloud size="60px" />} title="Wynajem" />
        <BenefitBlock icon={<FaCommentDollar size="60px" />} title="Zakup" />
        <BenefitBlock icon={<FaFile size="60px" />} title="Leasing" />
      </BenefitBlockWrapper>
      <br />
      <h3>
        <strong>Czym jest wynajem oprogramowania biznesowego? </strong>
      </h3>
      <p>
        {" "}
        Wynajem to rozwiązanie, które polega na tym, że utrzymanie systemu na
        serwerze jest w zakresie naszych obowiązków. Twoim kosztem jest jedynie
        stała, określona w umowie kwota, a wszystkie pozostałe kwestie są po
        stronie dostawcy oprogramowania.
      </p>
      <p>
        <strong>Dlaczego warto?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>Brak kosztów utrzymania</strong> — utrzymanie Twojego
            systemu na bezpiecznych serwerach dostawcy.{" "}
          </li>
          <li>
            <strong>Pomoc i wsparcie</strong> — dostęp do pomocy technicznej
            oraz merytorycznej (how-to), a także bezpłatnego standardowego
            wsparcia ze strony dostawcy.
          </li>
          <li>
            <strong>Aktualizacje</strong> — stały bezpłatny dostęp do
            aktualizacji oprogramowania, zawierających nowe funkcjonalności oraz
            usprawnienia.
          </li>

          <li>
            <strong>Szybkie wdrożenie</strong> — model SaaS zapewnia
            błyskawiczny przebieg wdrożenia.
          </li>
          <li>
            <strong>Bezpieczeństwo</strong> — to dostawca zajmuje się ochroną
            bezpieczeństwa systemu i Twoich danych.
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong>Czym jest zakup oprogramowania biznesowego? </strong>{" "}
      </h3>
      <p>
        {" "}
        W tym modelu otrzymujesz licencję na system, a potem przechowujesz go na
        swoich serwerach. Zakup oprogramowania IT wiąże się z jego samodzielnym
        utrzymaniem i obsługą.
      </p>
      <p>
        <strong>Dlaczego warto?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong> Indywidualna licencja</strong> — otrzymujesz dożywotnią
            licencję na system.{" "}
          </li>
          <li>
            <strong>Aktualizacje</strong> — masz roczny dostęp do wielu
            aktualizacji, zawierających nowe funkcjonalności oraz usprawnienia.
          </li>
          <li>
            <strong>Pełna kontrola nad danymi</strong> — sam decydujesz o tym,
            jak chronisz swoje dane.
          </li>
        </ul>
      </p>
      <br />
      <h3>
        <strong> Czym jest leasing oprogramowania biznesowego? </strong>
      </h3>
      <p>
        {" "}
        Leasing IT dotyczy wyłącznie jednorazowego zakupu oprogramowania. W
        ramach umowy leasingowej możliwe jest finansowanie zakupu systemu oraz
        usługi wdrożeniowej. Państwo zobowiązują się do ponoszenia miesięcznych
        rat leasingowych w wyznaczonym okresie.
        <strong>
          Ta metoda finansowania pozwala rozłożyć koszty projektu wdrożeniowego
          nawet na 5 lat.
        </strong>
      </p>
      <p>
        <strong>Dlaczego warto?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>Umożliwia finansowanie całego projektu</strong> — zakupu
            licencji oraz usług wdrożeniowych jednocześnie.{" "}
          </li>
          <li>
            <strong>Minimum formalności</strong> — liczba wymaganych dokumentów
            została ograniczona do minimum.
          </li>
          <li>
            <strong>Współpracujemy z doświadczonym ekspertem</strong> w
            dziedzinie leasingu IT – Grenke Leasing - indywidualna obsługa i
            wsparcie merytoryczne na każdym etapie.
          </li>
          <li>
            <strong>Redukcja kosztów</strong> — raty leasingowe zaliczane są w
            całości do kosztów uzyskania przychodu, co wpływa na obniżenie
            podstawy opodatkowania.
          </li>
          <li>
            <strong>Ochrona kapitału firmy</strong> — leasing IT nie obciąża
            bilansu finansowego i tym samym nie pogarsza jej bieżącej kondycji.
          </li>
        </ul>
      </p>
      <br />
      <br />

      <h2> Chcesz porozmawiać o Twoim projekcie? </h2>

      <Container>
        <div
          style={{
            textAlign: "center",
            margin: "20px",
            padding: "10px",
          }}
        >
          <Link className={styles.startNow} to="/kontakt/">
            Skontaktuj się z nami
          </Link>
        </div>
      </Container>
    </FunctionPageLayout>
  );
};

export default Metodyfinansowania;
